



























import { defineComponent, computed, onBeforeMount } from "@vue/composition-api";
import { ApplicantCreateBody, Service, User, Provider } from "@/store";
import ApplicantForm from "@/components/forms/Applicant.vue";
import { ApplicantActions, ApplicantGetters } from "../../store/applicant";
import {
  useStore,
  useApplicantService,
  useServiceTypeService,
  useRouter,
  useProviderService,
} from "@/composables/providers";
import { ServiceActions, ServiceGetters } from "@/store/service-type";
import { SessionGetters } from "../../store/session/index";
import { ProviderActions, ProviderGetters } from "@/store/provider";
export default defineComponent({
  name: "ApplicantsCreate",
  components: {
    ApplicantForm,
  },
  setup() {
    const store = useStore();
    const servicesService = useServiceTypeService();
    const providerService = useProviderService();
    const router = useRouter();
    const applicantService = useApplicantService();
    const applicantsLoading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );
    const providersLoading = computed<boolean>(
      () => store.getters[ProviderGetters.LOADING]
    );
    const servicesLoading = computed<boolean>(
      () => store.getters[ServiceGetters.LOADING]
    );
    const loading = computed<boolean>(
      () =>
        applicantsLoading.value ||
        servicesLoading.value ||
        providersLoading.value
    );
    const militaryServices = computed<Service[]>(
      () => store.getters[ServiceGetters.MILITARY_SERVICES]
    );
    const responderServices = computed<Service>(
      () => store.getters[ServiceGetters.RESPONDER_SERVICES]
    );
    const currentUser = computed<User>(
      () => store.getters[SessionGetters.USER]
    );

    const providers = computed<Provider[]>(
      () => store.getters[ProviderGetters.PROVIDERS]
    );
    async function submit(body: ApplicantCreateBody) {
      const applicant = await store.dispatch(ApplicantActions.CREATE, {
        body,
        service: applicantService,
      });
      if (applicant && applicant.id) {
        await store.dispatch(ApplicantActions.SELECT, {
          id: applicant.id,
          service: applicantService,
        });
        await router.push({
          name: "ApplicantDetail",
          params: { id: applicant.id },
        });
        await store.dispatch(ProviderActions.LIST, {
          service: providerService,
          force: true,
        });
      }
    }

    onBeforeMount(async () => {
      await Promise.all([
        store.dispatch(ServiceActions.LIST, { service: servicesService }),
        store.dispatch(ProviderActions.LIST, { service: providerService }),
      ]);
    });

    return {
      submit,
      loading,
      store,
      applicantService,
      applicantsLoading,
      servicesLoading,
      providersLoading,
      militaryServices,
      responderServices,
      currentUser,
      providers,
    };
  },
});
